<template>
  <div>
    <v-progress-circular :size="35" color="primary" v-if="loading" indeterminate />
    <template v-else-if="!employee">
      {{ "-" }}
    </template>
    <template v-else>
      <v-list-item style="padding-left: 0!important;">
        <v-list-item-avatar>
          <v-avatar left color="grey" v-if="employee.profile.imageUrl">
            <v-img :src="employee.profile.imageUrl" :lazy-src="employee.profile.imageUrl">
              <template v-slot:placeholder>
                <v-layout fill-height align-center justify-center ma-0>
                  <v-progress-circular indeterminate color="grey lighten-5" />
                </v-layout>
              </template>
            </v-img>
          </v-avatar>
          <v-avatar left color="primary" v-else>
            <div class="white--text font-weight-bold">
              {{ shortName }}
            </div>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title> {{ fullName }}</v-list-item-title>
          <v-list-item-subtitle> {{ branch }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </div>
</template>

<script>
  export default {
    name: "EmployeeAvatarProfile",
    props: {
      employee: {
        required: true
      },
      loading: {
        type: Boolean,
        required: false,
        default: () => false
      }
    },
    computed: {
      shortName() {
        return (
          (this.employee || "-").firstName.substring(0, 1).toUpperCase() +
          (this.employee || "-").lastName.substring(0, 1).toUpperCase()
        );
      },
      fullName() {
        return (this.employee || "-").firstName + " " + (this.employee || "-").lastName;
      },
      branch() {
        return this.$helpers.get(this.employee, "employment.branch.name") || "-";
      }
    },
    methods: {},
    created() {}
  };
</script>

<style scoped lang="sass"></style>
